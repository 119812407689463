import * as React from "react"
import Checkmark from "../../../images/checkmark.svg"
import Xmark from "../../../images/xmark.svg"

const Pricing = ({ pricing }) => { 
    const Item = ({ children, price, tall }) => {
        return (
            <div className={`
            bg-white py-2 
            text-center
            ${price ? 'text-[14px]' : 'text-[12px]'} 
            sm:text-[14px] lg:text-[26px]
            leading-tight
            my-2 lg:my-4 flex 
            justify-center 
            items-center 
            h-[42px] 
            ${tall && 'h-[56px] 1xs:h-[42px]'}
            `}>
                {children}
            </div>
        )
    }

    return (
    <div className="
        w-full my-8 lg:my-16
        bg-blue-darkest 
        rounded-lg pt-24 pb-12 px-2 lg:px-16
        xl:w-[90%] mx-auto"
    > 
        <div className="flex flex-row w-full justify-center lg:pt-[70]">
            <div className="flex flex-col w-2/3">
                {pricing.items.map((price, index) => (
                    <p 
                        className={`
                            bg-white 
                            text-[14px] 
                            md:text-[16px]
                            lg:text-[20px]
                            xl:text-[26px] 
                            py-1 px-2 
                            leading-tight
                            lg:leading-normal
                            lg:pl-6 xl:pl-8 my-2 lg:my-4 
                            h-[42px] 1xs:h-[42px]
                            ${price.item.includes('mentoring session') && 'h-[56px] 1xs:h-[42px]'}
                        `}
                        key={index}
                        >
                            {price.item}
                        </p>
                ))}
            </div>
            <div className="w-1/3 flex flex-row gap-1 sm:gap-2">
                <div className="w-1/2 flex flex-col -mt-[58px] lg:-mt-[74px]">
                    <Item inperson>In Person</Item>
                    <Item><img width="32px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Checkmark}/></Item>
                    <Item><img width="32px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Checkmark}/></Item>
                    <Item><img width="32px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Checkmark}/></Item>
                    <Item tall><img width="32px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Checkmark}/></Item>
                    <Item price tall>{pricing.pricesOnline.firstPrice}</Item>
                    <Item price>{pricing.pricesOnline.secondPrice}</Item>
                </div>
                <div className="w-1/2 flex flex-col -mt-[58px] lg:-mt-[74px]">
                    <Item>Online</Item>
                    <Item><img width="32px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Checkmark}/></Item>
                    <Item><img width="32px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Checkmark}/></Item>
                    <Item><img width="29px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Xmark}/></Item>
                    <Item tall><img width="29px" height="26px" className="w-[22px] h-[16px] lg:w-[32px] lg:h-[26px]" alt="" src={Xmark}/></Item>
                    <Item price tall>{pricing.pricesLive.firstPrice}</Item>
                    <Item price>{pricing.pricesLive.secondPrice}</Item>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Pricing 