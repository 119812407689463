import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';


// Static
import DatesSvg from "../../../images/info-dates.svg"
import VenueSvg from "../../../images/info-venue.svg"
import PackagesSvg from "../../../images/info-packages.svg"
import Subheading from "../../story/subheading";

const InfoCards = ({ infoCards }) => {

    const Card = ({ dates, venue, packages }) => {
        return dates ? (
            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <div className="
                    bg-white rounded-lg
                    flex flex-row
                    items-start
                    lg:items-center
                    justify-center
                    my-4
                    py-16 px-2 lg:px-0
                    lg:ml-16    
                    gap-x-4 lg:gap-x-8
                    w-full lg:max-w-[700px]"
                > 
                    <img
                        src={DatesSvg}
                        alt=""
                        className="max-w-[80px] lg:max-w-[200px]"
                    />
                    <div className="flex flex-col">
                        <Subheading text="Workshop Dates" 
                        customClass={"lg:text-[30px] font-bold mb-2 lg:mb-6"} />
                        <p className="text-base sm:text-2xl">
                            {dates.firstDate}
                        </p>
                        <p className="text-base sm:text-2xl">
                            {dates.secondDate}
                        </p>
                        <p className="text-base sm:text-2xl">
                            {dates.thirdDate}
                        </p>
                    </div>  
                </div> 
            </ScrollAnimation>
        ) : venue ? (
            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <div className="
                    bg-white rounded-lg
                    flex flex-row
                    items-start
                    lg:items-center
                    justify-center
                    my-4
                    gap-x-4 lg:gap-x-16
                    px-0 py-8
                    lg:ml-auto
                    lg:mr-16
                    w-full lg:max-w-[700px]"
                > 
                    <img
                        src={VenueSvg}
                        alt=""
                        className="max-w-[70px] lg:max-w-[200px]"
                    />
                    <div className="flex flex-col">
                        <Subheading text="Venue" 
                        customClass={"lg:text-[30px] font-bold mb-2 lg:mb-6"} 
                         />
                        <p className="text-base sm:text-2xl">
                            {venue.place}
                        </p>
                    </div>
                </div> 
            </ScrollAnimation>
        ) : packages ? (
            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <div className="
                    bg-white rounded-lg
                    flex flex-col
                    items-center
                    justify-center
                    p-8
                    my-4
                    w-full"
                > 
                    <div className="flex flex-col lg:flex-row gap-x-2 lg:gap-x-8 items-center">
                        <img
                            src={PackagesSvg}
                            alt=""
                            className="max-w-[90px]"
                        />
                        <Subheading 
                         customClass={"lg:text-[30px] font-bold mb-2 lg:mb-6"}
                        text="Packages" />
                    </div>
                    <div className="flex flex-col lg:flex-row justify-center mt-4 lg:border-t-2 border-neutral-dark">
                        <p className="text-base sm:text-2xl pt-4 pb-16 px-16 lg:border-r-2 border-neutral-dark">
                            {packages.firstPackage}
                        </p>
                        <p className="text-base sm:text-2xl pt-4 pb-16 px-16 lg:border-r-2 border-neutral-dark">
                            {packages.secondPackage}
                        </p>
                        <p className="text-base sm:text-2xl pt-4 pb-16 px-16">
                            {packages.thirdPackage}
                        </p>
                    </div>
                </div> 
            </ScrollAnimation>
        ) : null
    }
    return (
        <div className="flex flex-col">
            <Card dates={infoCards?.dates} />
            <Card venue={infoCards?.venue} />
            {/*<Card packages={infoCards?.packages} />*/}
        </div>
    )
}

export default InfoCards